import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getThumbInfo } from '../lib/icons';
import { prepUrl } from '../api/utils';

const FileThumb = ({ type, thumb }) => {
  const { width, height } = getThumbInfo(type);

  return (
    <div className="iconwrap">
      <img src={prepUrl(thumb)} width={width} height={height} />
    </div>
  );
}

export default FileThumb;
