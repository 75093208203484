// @flow

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";

import Modal, { CloseModalIcon } from "../Modal";
import { Loader } from "../../containers/TwoFactorAuth/SharedComponents";
import { ButtonCentered } from "../ButtonDefault";
import * as Style from "./styledComponents";

import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";
import { prepUrl } from "../../api/utils";
import { errorKeys } from "../../lib/errors";
import { styledSpanTagConverter } from "../../lib/componentifyConverters";

import useIsMount from "../../../web-shared/hooks/useIsMount";

const customConverter = Object.assign({}, styledSpanTagConverter, {
  props: {
    style: {
      color: "#17bed0",
      paddingLeft: "5px",
      maxWidth: "300px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    }
  }
});

type Props = {
  data: object,
  documentName: string,
  iframeSrc: any,
  candownload: Boolean,
  code: any,
  downloadmethod: string,
  downloadparams: object,
  offsetX?: string,
  offsetY?: string
};

const PreviewDocModal = ({
  data,
  documentName,
  iframeSrc,
  offsetX,
  offsetY,
  candownload,
  code,
  downloadmethod,
  downloadparams
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const [displayShareButton, setDisplayShareButton] = useState(false);
  const [displayDownloadButton, setDisplayDownloadButton] = useState(false);
  const [downloadButtonUrl, setDownloadButtonUrl] = useState("");

  const iframeEl = useRef(null);
  const isMount = useIsMount();

  useEffect(() => {
    if (candownload) {
      apiMethod(
        downloadmethod,
        downloadparams,
        ret => {
          const url = prepUrl(ret);
          setDisplayDownloadButton(true);
          setDownloadButtonUrl(url);

          if (!code) {
            setDisplayShareButton(true);
          }
        },
        {
          errorCallback: ({ result, error }) => {
            if (errorKeys[result]) {
              HFN.message(__(errorKeys[result]), "error");
            } else {
              HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            }
            throw new Error(error);
          }
        }
      );
    }
  }, [downloadmethod, downloadparams]); //[downloadmethod, downloadparams]

  useEffect(() => {
    if (iframeEl.current) {
      setIsLoadingIframe(false);
    }
  }, [isMount, iframeEl]); //[isMount, iframeEl]

  const handleClose = () => {
    setIsOpen(false);
  };

  const onDownlaodButtonClick = () => {
    location.href = downloadButtonUrl;
  };

  const onShareButtonClick = () => {
    HFN.getOrCreatePublink(
      data,
      link => {
        HFN.sharePublink(link);
      },
      { async: false }
    );

    setIsOpen(false);
  };

  return (
    <Modal shouldCloseOnOverlayClick={false} animate onClose={handleClose} opened={isOpen}>
      <ModalContainer offsetX={offsetX} offsetY={offsetY}>
        <CloseModalIcon onClick={handleClose} />
        <ContainerW offsetX={offsetX} offsetY={offsetY}>
          <Componentify
            text={`${__("Preview Document", "Preview Document")}<span>"${documentName}"</span>`}
            converters={[customConverter]}
          />
          <WrapperIframe offsetY={offsetY}>
            {isLoadingIframe ? <Loader /> : null}
            <IframeLink
              offsetX={offsetX}
              offsetY={offsetY}
              show={!isLoadingIframe}
              ref={iframeEl}
              src={iframeSrc}
              scrolling="no"
            />
            <ButtonWrapper>
              {displayDownloadButton ? (
                <ButtonCentered
                  inline
                  color="cyan"
                  style={{
                    marginRight: "5px",
                    maxWidth: "300px"
                  }}
                  onClick={onDownlaodButtonClick}
                >
                  {__("Download", "Download")}
                </ButtonCentered>
              ) : null}
              {displayShareButton ? (
                <ButtonCentered
                  inline
                  color="lightgray4"
                  style={{
                    marginRight: "5px",
                    maxWidth: "300px"
                  }}
                  onClick={onShareButtonClick}
                >
                  {__("Share", "Share")}
                </ButtonCentered>
              ) : null}
            </ButtonWrapper>
          </WrapperIframe>
        </ContainerW>
      </ModalContainer>
    </Modal>
  );
};

export default PreviewDocModal;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  div {
    display: inline;
  }
`;

const ContainerW = styled(Style.Container)`
  width: ${props => props.offsetX}px;
`;

const ModalContainer = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => props.offsetX}px;
  height: ${props => props.offsetY}px;
  background: #fff;
  border-radius: 4px;
  display: inline-block;
  overflow: hidden;
`;

const WrapperIframe = styled.div`
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: ${props => props.offsetY - 175}px;
`;

const IframeLink = styled.iframe`
  border: none;
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  background-color: #fff;
  overflow: hidden;
`;
