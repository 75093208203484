// @flow

import $ from "jquery";
import { __ } from "./translate";
import { ERROR_MESSAGE } from "@pcloud/web-utilities/dist/api/errors";
import apiConfig, { DEFAULT_LOCATIONS } from "../api/config";
import { LOCAL_HOSTNAMES, PROD_HOSTNAMES, US_LOCATIONID, EU_LOCATIONID } from "@pcloud/web-utilities/dist/config/constants";
import type { LocationType } from "../types/dataregion";
import { loadScript, rcookie, setcookie, hrefSearch, hashSearch, setCookieOnSibling } from "../../web-shared/utils";
import apiMethod from '../api/apiMethod';
import { API_SERVER } from '../../web-shared/api';

export { loadScript, rcookie, setcookie, hrefSearch, hashSearch, setCookieOnSibling };

export const noop = () => {};

export function fileListSize(filesList: Array<File>) {
  let size = 0;

  each(filesList, file => {
    size += file.size;
  });

  return size;
}

export function getCurrentLang(): string {
  if (typeof pCloudIntl !== "undefined") {
    return pCloudIntl.getLang();
  }
  if (typeof pCloud !== "undefined") {
    return pCloud.lang;
  }
}

export function __e(data: any): string {
  var error_code;
  var error_message;

  if (typeof arguments[0] == "object") {
    if (typeof arguments[0].result == "undefined") {
      throw Error("object must have property result");
    }
    if (typeof arguments[0].error == "undefined") {
      throw Error("object must have property error");
    }

    error_code = arguments[0].result;
    error_message = arguments[0].error;
  } else {
    error_code = arguments[0];
    error_message = arguments[1];
  }

  if (error_code in ERROR_MESSAGE) {
    error_message = ERROR_MESSAGE[error_code];
  }

  var trans = __(error_code);
  return trans && trans != error_code ? trans : error_message;
}

export function errorMessage(data: any) {
  return __e(data);
}

export function formatSize(sizebytes: number, prec: number = 1, precMb: number = 1) {
  sizebytes = parseInt(sizebytes, 10);

  // this has better performance since it is not accumulating rounding error
  if (sizebytes >= 1099511627776) {
    return (sizebytes / 1099511627776).toFixed(prec) + " " + __("Tb");
  } else if (sizebytes >= 1073741824) {
    return (sizebytes / 1073741824).toFixed(prec) + " " + __("Gb");
  } else if (sizebytes >= 1048576) {
    return (sizebytes / 1048576).toFixed(precMb) + " " + __("Mb");
  } else if (sizebytes >= 1024) {
    return (sizebytes / 1024).toFixed(prec) + " " + __("Kb");
  } else {
    return sizebytes.toFixed(prec) + " " + __("B");
  }
}

export function formatSizeInGb(sizebytes: number, prec: number = 1, precMb: number = 1) {
  sizebytes = parseInt(sizebytes, 10);

  return (sizebytes / 1073741824).toFixed(prec) + " " + __("Gb");
}

type dateInput = string | number;

export function toParseDt(date: dateInput) {
  var d = new Date(date);
  return (
    d.getFullYear() +
    "-" +
    (parseInt(d.getMonth()) < 9 ? "0" : "") +
    (d.getMonth() + 1) +
    "-" +
    (d.getDate() < 10 ? "0" : "") +
    d.getDate()
  );
}

export function toTime(date: dateInput) {
  return new Date(date).getTime();
}

export function allignTime(date: dateInput) {
  return toTime(toParseDt(date));
}

export function formatDt(date: dateInput) {
  return new Date(date).toLocaleDateString();

  /*
  var d = new Date(date);
  return (d.getDate() < 10 ? '0' : '') + d.getDate() + '/' + (parseInt(d.getMonth()) < 9 ? '0' : '') + (d.getMonth() + 1) + '/' + d.getFullYear();
	*/
}

export function formatDtTime(date: dateInput) {
  return new Date(date).toLocaleString();
}

export function prepDt(date: dateInput) {
  return new Date(date).toLocaleString();
}

export function formatTime(seconds: number, withWords: boolean = false, leastTwo: boolean = false) {
  var r: Array<any> = [];
  var perI = 60;

  r.unshift(seconds);
  while (r[0] > perI - 1) {
    r.unshift(Math.floor(r[0] / perI));

    r[1] = r[1] % perI;

    if (r[1].toString().length < 2) r[1] = "0" + r[1];

    perI = r.length >= 3 ? 24 : 60;
  }

  if (r[0].toString().length < 2) r[0] = "0" + r[0];

  if (leastTwo && r.length == 1) r.unshift("00");

  if (withWords) {
    var words = [__("sec"), __("min"), __("hour"), __("day")],
      wordsM = [__("secs"), __("mins"), __("hours"), __("days")],
      n = r.length - 1,
      ret = [];
    for (; n >= 0; --n)
      if (parseInt(r[n])) {
        var use = parseInt(r[n]) > 1 ? wordsM : words;
        ret.unshift(
          r[n] + "" + use[Math.abs(n - r.length + 1)] /*+ (parseInt(r[n]) > 1 ? 's' : '')*/
        );
      }
    return ret.join(" ");
  }
  return r.join(":");
}

export function toBeautyDate(date: dateInput) {
  var d = new Date(date);
  var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  return (
    months[d.getMonth()] +
    " " +
    ((d.getDate() < 10 ? "0" : "") + d.getDate()) +
    ", " +
    d.getFullYear()
  );
}

export function toBeautyMonthYear(date: dateInput) {
  var d = new Date(date);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  return months[d.getMonth()] + " " + d.getFullYear();
}

export function retinaSrc(src: string) {
  if (window.devicePixelRatio >= 2) src = src.replace("." + fileext(src), "@2x." + fileext(src));
  return src;
}

export function fileext(file: string) {
  return file.split(".").pop();
}

export function range(limit: number, step: number = 1, start: number = 1) {
  let ret = [];

  for (let n = start; n < limit + start; n += step) {
    ret.push(n);
  }

  return ret;
}

export function obLength(obj: {}) {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) {
      size++;
    }
  }
  return size;
}

export function getTimeZone(strTime: string) {
  const date = (strTime ? new Date(strTime) : new Date()).toString();
  const match = date.match(/\d{2}\:\d{2}\:\d{2}/);

  if (match) {
    return date.substr(date.indexOf(match[0]) + match[0].length + 1);
  }
  //const match = [0];
}

export function getUTCOffset(strTime: dateInput) {
  const off = (strTime ? new Date(strTime) : new Date()).getTimezoneOffset() / 60;

  return (off > 0 ? "-" : "+") + /*(off < 10 ? '0' : '') +*/ Math.abs(off) /* + '00'*/;
}

export function getUTCOffsetMiliseconds(strTime: dateInput) {
  return (strTime ? new Date(strTime) : new Date()).getTimezoneOffset() * 60 * 1000;
}

export function map<T, V>(list: Array<T>, func: T => V) {
  var ret: Array<V> = [];
  for (var i = 0; i < list.length; ++i) {
    ret.push(func(list[i]));
  }

  return ret;
}

export function objMap<T, V>(obj: { [key: mixed]: T }, func: T => V): { [key: mixed]: V } {
  var ret = {};

  for (let i in obj) {
    ret[i] = func(obj[i]);
  }

  return ret;
}

//

export function each<T>(list: Array<T>, func: T => void) {
  for (var i = 0; i < list.length; ++i) {
    func(list[i]);
  }
}

export function filter(list: Array<{}>, filter: {}, useMatch: boolean, all: boolean) {
  if (all == undefined) {
    all = true;
  }

  var r = [];
  var match = useMatch || false;
  var matchN = all ? obLength(filter) : 1;
  var n = 0;
  var k = 0;
  var cMatch;
  var ret;

  for (var n = 0; n < list.length; ++n) {
    ret = true;
    cMatch = 0;
    for (var k in filter) {
      if (
        k in list[n] &&
        ((!match && list[n][k] == filter[k]) ||
          (match && list[n][k].toLowerCase().match(filter[k].toLowerCase())))
      )
        cMatch++;
    }

    if (cMatch >= matchN) {
      r.push(list[n]);
    }
  }

  return r;
}

export function htmlentities(str: string) {
  return $("<div>")
    .text(str)
    .html();
}

export function validateEmail(email: string) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\	".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validatePassword(password: string) {
  let error = "";

  if (password.length < 6) {
    error = __("password_too_short", "Password is too short.");
  }

  if (password.length === 0) {
    error = __("password_required", "Password is requried.");
  }

  return error;
}

export function validateName(name: string) {
  if (name.length == 0 || name.length > 40) {
    return false;
  }
  var pattern = new RegExp(/^[ 0-9a-zA-Z\u0400-\u04FF\-]+$/);
  return pattern.test(name);
}

export function dataUriToBlob(dataURI: string, contentType: string) {
  var byteString = atob(dataURI.split(",")[1]);
  var mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  var bb = new Blob([ab], { type: contentType });

  return bb;
}

export function obMegaLength(obj: {}) {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] == "object") {
        size += obMegaLength(obj[key]);
      } else {
        size += 1;
      }
    }
  }
  return size;
}

export function getSearch(param: string) {
  return getQuery(param, location.search.substr(location.search.indexOf("?") + 1));
}

export function getHash(param: string) {
  return getQuery(param, location.hash.substr(location.hash.indexOf("#") + 1));
}

function getQuery(param, query) {
  for (let pair of query.split("&")) {
    let [key, val] = pair.split("=");

    if (key === param) {
      return decodeURIComponent(val);
    }
  }
}

export const parseQueryString = (queryString: string): {} => {
  const paramsArr = queryString.substring(1).split("&");

  return paramsArr.reduce((acc, pair) => {
    const [key, value] = pair.split("=");

    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});
};

export function unusedFunction() {
  console.log("RANDOM CODE");
}

export function onDocumentReady(func: Function) {
  if (document.readyState == "complete") {
    func();
  } else {
    $(window).on("load", func);
  }
}

export function isInternalUrl(url: string): boolean {
  const domains = [
    "127.0.0.1",
    "[a-z]{2,5}.pcloud.com",
    "pcloud",
    "u.localhost",
    "e.localhost",
    "localhost",
    "pclmy.pclocal.kom",
    "[a-z].pclocal.link"
  ];

  if (["/", "#"].indexOf(url.charAt(0)) !== -1) {
    return true;
  }

  for (let domain of domains) {
    if (url.match(new RegExp("//" + domain + "/"))) {
      return true;
    }
  }

  return false;
}

export function convertTime(date: string) {
  const rightNow = new Date();
  const then = new Date(date);
  const difference = rightNow - then;

  const secondsWithin = 1000;
  const minutesWithin = secondsWithin * 60;
  const hoursWithin = minutesWithin * 60;
  const daysWithin = hoursWithin * 24;
  const weeksWithin = daysWithin * 7;
  const monthsWithin = daysWithin * 30;

  if (difference < secondsWithin * 5) {
    return "just now";
  }

  if (difference < minutesWithin) {
    const seconds = Math.floor(difference / secondsWithin);
    return seconds === 1 ? __("social_proof_second_ago", "1 second ago") : __("social_proof_seconds_ago", "%interval% seconds ago", { interval: seconds })
  }

  if (difference < hoursWithin) {
    const minutes = Math.floor(difference / minutesWithin);
    return minutes === 1 ? __("social_proof_minute_ago", "1 minute ago") : __("social_proof_minutes_ago", "%interval% minutes ago", { interval: minutes })
  }

  if (difference < daysWithin) {
    const hours = Math.floor(difference / hoursWithin);
    return hours === 1 ? __("social_proof_hour_ago", "1 hour ago") : __("social_proof_hours_ago", "%interval% hours ago", { interval: hours })
  }

  if (difference < weeksWithin) {
    const days = Math.floor(difference / daysWithin);
    return days === 1 ? __("social_proof_day_ago", "1 day ago") : __("social_proof_days_ago", "%interval% days ago", { interval: days })
  }

  if (difference < monthsWithin) {
    const weeks = Math.floor(difference / weeksWithin);
    return weeks === 1 ? __("social_proof_week_ago", "1 week ago") : __("social_proof_weeks_ago", "%interval% weeks ago", { interval: weeks })
  }

  if (difference < daysWithin * 365) {
    const months = Math.floor(difference / monthsWithin);
    return months === 1 ? __("social_proof_month_ago", "1 month ago") : __("social_proof_months_ago", "%interval% months ago", { interval: months })
  }

  if (difference > daysWithin * 365) {
    return __("social_proof_over_year", "over a year ago")
  }
}

export const setDeviceID = () => {
  let IDs = [];
  for (var i = 0; i < 4; i++) {
    let code = Math.random()
      .toString(36)
      .substr(2, 9);
    IDs.push(code);
  }
  const deviceID = IDs.join("");

  // window.localStorage.setItem('deviceid', deviceID);
  setcookie("deviceid", deviceID, 365);

  return deviceID;
};

export const getDeviceID = () => {
  try {
    if (!window.localStorage.getItem("deviceid") && !rcookie("deviceid")) {
      setDeviceID();
      return rcookie("deviceid");
    } else if (rcookie("deviceid")) {
      if (window.localStorage.getItem("deviceid")) {
        window.localStorage.removeItem("deviceid");
      }
      return rcookie("deviceid");
    } else if (window.localStorage.getItem("deviceid")) {
      setcookie("deviceid", window.localStorage.getItem("deviceid"), 365);
      window.localStorage.removeItem("deviceid");
      return rcookie("deviceid");
    }
  } catch (error) {
    return setDeviceID();
  }
};

export const makeTextFile = (text: string) =>
  window.URL.createObjectURL(new Blob([text], { type: "text/plain" }));

export function loadFbApi() {
  if (typeof FB != "undefined" && FB != null) {
    return new Promise(resolve => resolve(FB));
  }
  return new Promise((resolve, reject) => {
    // this loads the Facebook API
    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    window.fbAsyncInit = function() {
      var appId = "397181747048725";

      FB.init({
        appId: appId,
        xfbml: true,
        version: "v2.9"
      });
      resolve(FB);
    };
  });
}

export function detectIsMobile() {
  return /Mobile|mini|Fennec|Android|iP(od|hone)/.test(navigator.appVersion);
}

export function detectIsIOS() {
  return /iP(od|hone)/.test(navigator.appVersion);
}

export function detectSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

// export function daysFrom(date: Date) {
//   let today = new Date();
//   let dateFrom = new Date(date)
//   let daysWithin = (today - dateFrom)/(1000*3600*24);
//   daysWithin = Math.floor(daysWithin);
//   return daysWithin;
// };

// export const showManychatCheckbox = () => {
//   const hasStorage = !(
//     typeof Storage === "undefined" ||
//     typeof localStorage === "undefined" ||
//     localStorage === null
//   );

//   const isSubscribedForBot = hasStorage
//     ? localStorage.getItem("subforfbchatbot")
//     : false;
//   const isSupportedBrowser = !(detectIsMobile() || detectSafari());

//   return getCurrentLang() === "en" && isSupportedBrowser && !isSubscribedForBot;
// }

export const calcRelativeOffset = (
  currentWidth: number,
  originlWidth: number,
  originalOffset: number
) => {
  return Math.round((originalOffset * currentWidth) / originlWidth);
};

export const calcCoverHeight = (coverHeight: number, coverWidth: number) => {
  var windowWidth = window.innerWidth;
  return (coverHeight * windowWidth) / coverWidth;
};

export const getCoverPosition = (
  coverOffset: number,
  coverHeight: number,
  coverContainerHeight: number
) => {
  var visibleCoverHeight = coverHeight - coverOffset;
  return visibleCoverHeight < coverContainerHeight ? "bottom" : -coverOffset;
};

export const getCoverSize = (
  coverOffset: number,
  coverHeight: number,
  coverContainerHeight: number
) => {
  var visibleCoverHeight = coverHeight - coverOffset;
  return visibleCoverHeight < coverContainerHeight ? "cover" : "100%";
};

export const setGoogleExperiment = (id: string, variations: Array) => {
  var chosenVariation = 0;
  var script = document.createElement("script");

  script.src = "//www.google-analytics.com/cx/api.js?experiment=" + id;
  document.getElementsByTagName("body")[0].appendChild(script);

  script.onload = function() {
    chosenVariation = cxApi.chooseVariation();
    variations[chosenVariation]();
  };

  script.onerror = function() {
    variations[0]();
  };
};

export const identicalStrings = (str1: string, str2: string) => {
  return str1.toLowerCase() === str2.toLowerCase();
};

export const debounce = (func: () => void, delay: number) => {
  let timeout = null;

  return () => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };
};

export const isInFixedContainer = (target) => {
  while (target) {
    target = target.parentNode;
    if (!isDomElement(target)) {
      break;
    }
    const computedStyles = getComputedStyle(target);
    if (computedStyles.position === 'fixed') {
      return true;
    }
  }
  return false;
};

export const isDomElement = (obj) => {
  try {
    return obj instanceof HTMLElement;
  }
  catch(e){
    return (typeof obj==="object") &&
        (obj.nodeType===1) && (typeof obj.style === "object") &&
        (typeof obj.ownerDocument ==="object");
  }
}


// data region
export const getLocationById = (id: number): LocationType | null => {
  return DEFAULT_LOCATIONS.filter(location => location.id == id)[0] || null;
};

export const getLocationFromUrl = (): LocationType | null => {
  const hostName = window.location.hostname;
  const locationIDParam = hrefSearch("locationid") || hashSearch("locationid");

  const usDomains = [
    "u.localhost",
    "u.pcloud.com",
    "u.pcloud.link",
    "u.pclocal.link",
    "myqa1.pcloud.com",
    "myte72.pcloud.com",
    "myte75.pcloud.com",
  ];
  
  const euDomains = [
    "e.localhost",
    "e.pcloud.com",
    "e.pcloud.link",
    "e1.pcloud.link",
    "e.pclocal.link",
    "emyqa1.pcloud.com",
    "emyqa2.pcloud.com",
    "emyqa3.pcloud.com",
    "emyqa4.pcloud.com",
    "emyqa5.pcloud.com",
  ];
  
  let location = getLocationById(US_LOCATIONID);

  usDomains.forEach(domain => {
    if (hostName.match(new RegExp(domain))) {
      location = getLocationById(US_LOCATIONID);
    }
  });

  euDomains.forEach(domain => {
    if (hostName.match(new RegExp(domain))) {
      location = getLocationById(EU_LOCATIONID);
    }
  });

  if (locationIDParam) {
    location = getLocationById(locationIDParam);
  }

  return location;
};

export const getGlobalLocationId = () => {
  return apiConfig.locationid;
};

export const getUserLocationHost = () => {
  return PROD_HOSTNAMES[apiConfig.locationid];
};

export const isSameLocation = () => {
  const locationidFromUrl = getLocationFromUrl() && getLocationFromUrl().id;
  console.log("isSameLocation", apiConfig.locationid, locationidFromUrl);
  return parseInt(apiConfig.locationid) === parseInt(locationidFromUrl);
};

export const getHostNameByLocationId = () => {
  const locationId = rcookie("locationid") || US_LOCATIONID;

  if (DEBUG) {
    return LOCAL_HOSTNAMES[locationId];
  } else {
    return PROD_HOSTNAMES[locationId];
  }
};

export const isUSLocation = () => {
  return apiConfig.locationid === US_LOCATIONID;
};

export const isEULocation = () => {
  return apiConfig.locationid === EU_LOCATIONID;
};

type creditCardValidation = {
  name: string,
  range: string,
  valid_length: Array<string>
};

export const findCardType = (cardNumber: string): creditCardValidation => {
  const creditCardValidations = [
    {
      name: "amex",
      range: "34, 37",
      valid_length: ["15"]
    },
    {
      name: "diners",
      range: "301, 302, 303, 304, 305, 36",
      valid_length: ["14"]
    },
    {
      name: "jcb",
      range: "352, 353, 354, 355, 357, 358",
      valid_length: ["16"]
    },
    {
      name: "visa",
      range: "4",
      valid_length: ["19", "18", "17", "16", "15", "14", "13"]
    },
    {
      name: "mastercard",
      range: "50, 51, 52, 53, 54, 55, 222",
      valid_length: ["16"]
    },
    {
      name: "discover",
      range: "6011, 622, 644, 645, 646, 647, 648, 649",
      valid_length: ["16"]
    }
  ];

  return creditCardValidations.find((cardTemplate: creditCardValidation): creditCardValidation => {
    let isThereAMatch = cardTemplate.range
      .split(", ")
      .some((range: string) => range === cardNumber);
    return isThereAMatch;
  });
};

export const getFullExpDate = (expiryDate: string) => {
  let month = Number(expiryDate.split("/")[0]);
  let year = Number(expiryDate.split("/")[1]);
  year += 2000;
  return month + " / " + year;
};

export const convertStringToMMYY = (date: string) => {
  let dateValue = date.slice();
  let month = "";
  let year = "";
  //removes all non-numeric characters
  dateValue = dateValue.replace(/[^\d]/g, "");

  switch (dateValue.length) {
    case 1:
      month = "0" + dateValue;
      year = " ";
      break;
    case 2:
      month = dateValue.slice(0, 2);
      year = " ";
      break;
    case 3:
      month = "0" + dateValue.slice(0, 1);
      year = dateValue.slice(1, 3);
      break;
    default:
      month = dateValue.slice(0, 2);
      year = dateValue.slice(-2);
  }
  return month + " / " + year;
};

export const convertDateToDDMMMYY = (date: string) => {
  const monthNames = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec"
  ];
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = monthNames[dateObj.getMonth()];
  const monthKey = "month_" + month;
  const year = dateObj.getFullYear();

  return day + " " + __(monthKey) + " " + year;
};

export const copyTextToClipboard = (text: string, successMessage: string) => {
  let copysupported = document.queryCommandSupported("copy"),
    is_opera = navigator.userAgent.toLowerCase().indexOf("opera") == -1;

  if (is_opera) copysupported = true;
  if (copysupported) {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);

    el.select();
    document.execCommand("copy");

    document.body.removeChild(el);


    if (typeof successMessage !== "undefined" && successMessage !== "") {
      HFN.message(successMessage);
    }
  }
  if (!copysupported) {
    HFN.message(__("browser_doesnt_support_operation", "Your browser doesn't support this operation. Please copy manually"));
  }
};

export const roundHours = date => {
  date.setHours(date.getHours() + Math.ceil(date.getMinutes() / 60));
  date.setMinutes(0, 0, 0);

  return date;
};

// Payment 3D secure Iframe
export const open3DsecureIframe = data => {
  let cont = HFN.renderTemplate("#sf_modal");

  const iframeParams = {
    id: "3d_secure_iframe",
    name: "3d_secure_iframe",
    src: ""
  };

  cont.find(".modal_holder").css("padding-top", "0px");

  if (!detectIsMobile()) {
    iframeParams["height"] = $(window).height() - 132;

    if (!onResizeFunctions["3d_secure_iframe_resize"]) {
      onResizeFunctions["3d_secure_iframe_resize"] = function() {
        $("#3d_secure_iframe").css({ height: $(window).height() - 132 });
      };
    }
  }

  $("#3d_secure_iframe, #send-sf").remove();

  Popup.open(cont, {});

  cont.find(".close").on("click", function() {
    Popup.close();
  });

  // Append Iframe to the modal
  cont.find(".content").append($("<iframe>", iframeParams));

  // Append Form and target previously added Iframe
  $(".safecharge_wrap .inner").append(
    $("<form>", {
      id: "send-sf",
      action: data.redirecturl || data.url,
      method: "post",
      target: "3d_secure_iframe"
    })
  );

  // Append needed inputs in the Form
  $("#send-sf").append(
    $("<input />", { type: "hidden", name: "PaReq", value: data.redirectdata || data.data.PaReq })
  );
  $("#send-sf").append(
    $("<input />", { type: "hidden", name: "TermUrl", value: data.termurl || data.data.TermUrl })
  );

  if (data.data && data.data.MD) {
    $("#send-sf").append($("<input />", { type: "hidden", name: "MD", value: data.data.MD }));
  }

  $("#send-sf").submit();

  $("#3d_secure_iframe")
    .off("load")
    .on("load", function() {
      setTimeout(function() {
        $("#3d_secure_iframe").fadeIn();
      }, 500);
    });
};

export const on3DsecureError = () => {
  $(".modal.sf_modal")
    .find("#sf_iframe")
    .css({ "padding-top": 0, opacity: 0 });
  $(".modal.sf_modal")
    .find(".close")
    .hide();
  closeModal();
  $("#sf_iframe").remove();
};

export const calculateUserQuota = (usedquota, freequota, quota) => {
  const totalQuota = freequota ? (freequota < quota ? quota : freequota) : quota;
  let usedFracrion = Math.min(1, Math.max(0, usedquota / totalQuota));
  let freeFraction = Math.min(1, Math.max(0, (quota - usedquota) / totalQuota));

  if (usedFracrion > 0 && usedFracrion < 0.02) {
    usedFracrion = 0.02;
  }
  if (freeFraction > 0 && freeFraction < 0.02) {
    freeFraction = 0.02;
  }

  let fractions = [
    {
      percent: usedFracrion.toFixed(2),
      color: "#5cb900",
      title: __("used_space"),
      size: formatSize(usedquota)
    },
    {
      percent: freeFraction.toFixed(2),
      color: "#c0e699",
      title: __("free_space"),
      size: formatSize(Math.max(0, quota - usedquota))
    }
  ];

  if (freequota) {
    //free user
    const lockedFraction = Math.min(1, Math.max(0, (freequota - quota) / freequota));
    if (lockedFraction != 0) {
      fractions = [
        ...fractions,
        {
          percent: lockedFraction.toFixed(2),
          color: "#e6e6e6",
          title: __("locked_space"),
          size: formatSize(Math.max(0, freequota - quota))
        }
      ];
    }
  }

  return fractions;
};

export const diffDates = (d1, d2) => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  console.log(diffDays + " days");

  return diffDays;
};

export const isBetween = (num, start, end) => {
  return (num - start) * (num - end) <= 0;
};

export const getRandomNumber = (maxNumber: number) => {
  return Math.floor(Math.random() * Math.floor(maxNumber));
};

export const currencyToSign = cur => {
  const curToSign = {
    'USD': '$',
    'EUR': '€',
    'GBP': '₤'
  };
  return curToSign[cur];
};

export const commaSep = (val) => {
	while (/(\d+)(\d{3})/.test(val.toString())){
	  val = val.toString().replace(/(\d+)(\d{3})/, '$1'+','+'$2');
	}
	return val;
};

// get subscription price
export const getSubsPrice = (users, period, pricePerUser) => {
  const months = period === 1 ? 12 : 1;
  let totalPrice = parseFloat(users * pricePerUser * months);
  const rls = HFN.config.user.language == "fa";

  const result = rls ? (totalPrice *= 60000) : totalPrice;

  return result.toFixed(2);
};

 export const getPriceWithCurrency = (price = 0, cur = "$") => {
  const rls = HFN.config.user.language == "fa";

  return rls
    ? commaSep(price) + " ریال"
    : price + " " + cur;
};

export const hideEmail = (email: string) => {
  return email.replace(/(.{2})(.*)(?=@)/, '$1*');
};

export const getIconType = (folder, opts = {}) => {
  let iconName = HFN.metaIcon(folder);
  if (!folder.ismine && folder.ismount){
    iconName = folder.encrypted ? HFN.ICO.FOLDER_SHAREDWITHME_CRYPTO : HFN.ICO.FOLDER_SHAREDWITHME;
  }

  if (folder.encrypted && iconName === HFN.ICO.FOLDER) {
    iconName = HFN.ICO.CRYPTO_FOLDER;
  } else if (HFN.config.isBackup(folder) && iconName === HFN.ICO.FOLDER && !opts.inTrash) {
    iconName = HFN.ICO.FOLDER_BACKUP;
  }

  if (folder.isshared) {
    HFN.getSharedStatus(folder, function (shares) {
      if (shares.share) {
        iconName = folder.encrypted ? HFN.ICO.FOLDER_SHARED_CRYPTO : HFN.ICO.FOLDER_SHARED;
      }
    });
  } else if (folder.ispublicroot) {
    iconName = HFN.ICO.PUBLIC;
  } else if (folder.isfolder) {
    iconName = HFN.ICO.FOLDER;
  }

  return iconName;
};

export const getIconSrc = (meta, opts = {}) => {
  const iconName = getIconType(meta, opts = {});
  return HFN.createIconSrc(iconName, HFN.ICONS.LIST_SMALL);
};

export const sortByName = (a, b, dataLength) => {
  let a2 = HFN.metaName(a);
  let b2 = HFN.metaName(b);

  let m = 1; // sortDirection == 'asc'
  if (a2 == undefined && b2)
    return -1 * m;
  else if (a2 && b2 == undefined)
    return 1 * m;
  else if (a2 == undefined && b2 == undefined) {
    if ("name" in a && "name" in b) {
      return a.name.localeCompare(b.name);
    }

    return 0;
  }

  if (typeof a2 == 'boolean')
    a2 = a2 ? 1 : 0;
  if (typeof b2 == 'boolean')
    b2 = b2 ? 1 : 0;

  if (!isNaN(parseFloat(a2)) && isFinite(a2) && !isNaN(parseFloat(b2)) && isFinite(b2)) {
    return (a2 - b2) * m;
  } else {
    try {
      if (dataLength < 500) {
        return naturalSort(a2, b2) * m;
      } else {
        return a2.localeCompare(b2) * m;
      }
    } catch (e) {
      console.log(e);
      console.log(a2, b2);
    }
  }
};

export const filterAndSort = (items = [], isCrypto = false) => {
  let filterArr = items.filter(item => {
    if (isCrypto) {
      return item;
    } else if (!item.encrypted) {
      return item;
    }
  });
  let size = items.length || items.size;
  let sortedItems = filterArr.sort((a, b) => sortByName(a,b, size));

  return sortedItems.sort((a, b) => b.isfolder - a.isfolder);
};

export const getDefaultFolderName = folderid => {
  const isPublink = Boolean(HFN.config.isDlink());
  let fname = __("New Folder");
  let n = 1;

  while (HFN.fileExists(folderid, fname, isPublink)) {
    fname = "New Folder (" + n + ")";
    ++n;
  }
  return fname;
};

export const getDefaultFolderNamePicker = (folder) => {
  if (!folder.contents) {
    return;
  }
  let fname = __("New Folder");
  let n = 1;

  const alreadyExist = () => {
    return folder.contents.find(item => {
      const itemName = item.encrypted ? HFN.metaName(item) : item.name;
      return itemName.toLowerCase() === fname.toLowerCase()
    });
  };

  while (alreadyExist()) {
    fname = "New Folder (" + n + ")";
    ++n;
  }
  return fname;
};

export const getFolderIds = (meta) => {
  let folderids = [];

  if (meta.isfolder) {
    folderids.push(meta.folderid);
  }

  if (meta.contents) {
    for (let n = 0; n < meta.contents.length; ++n) {
      if (meta.contents[n].isfolder) {
        folderids.push(meta.contents[n].folderid);
      }
    }
  }
  return folderids;
};

export const percentage = (partialValue, totalValue) => {
  return (100 * partialValue) / totalValue;
};

export const isInternetExplorer = () => {
  return !!document.documentMode;
};

export const checkPassToken = (cloudEmail) => {
  var passtoken = rcookie("passtoken");

  if (passtoken) {
    apiMethod("user/info", { auth: passtoken },  function(res) {
      var passEmail = res.email;

      if (passEmail !== cloudEmail) {
        setcookie("passtoken", "", -1);
      }
    }, { errorCallback: function(error) {
      console.log("checkPassToken error", error)
    }, apiServer: API_SERVER, showErrorMessage: false })
  }
};

export const isValidUrl = urlString => {
  try { 
    return Boolean(new URL(urlString)); 
  }
  catch(e){ 
    return false; 
  }
}

export const isFacebookBrowser = () => {
  const ua = navigator.userAgent || navigator.vendor

  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1
}


export const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

export const fibonacci = (n) => {
  if (n <= 1) return n;
  let a = 0, b = 1, sum = 0;
  for (let i = 2; i <= n; i++) {
    sum = a + b;
    a = b;
    b = sum;
  }
  return sum;
}

export const delay = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getImageSize = (img) => {
  const imgUrl = HFN.prepUrl(img);

  return new Promise((resolve, reject) => {
    const ajaxPromise = new Promise((resolve, reject) => {
      $.ajax({
        url: imgUrl,
        headers: { 'Send-Resolution': '1' },
        type: 'HEAD',
        cacheTime: 300,
        timeout: 30000,
        success: resolve,
        error: reject
      });
    });

    ajaxPromise.then(function (data, textStatus, jqXHR) {
      const header = jqXHR.getResponseHeader('X-Resolution');

      if (header) {
        const sizes = header.split('x');

        if (sizes.length === 2) {
          resolve({ path: img.path, width: sizes[0], height: sizes[1] });
        } else {
          loadImage(imgUrl);
        }
      } else {
        loadImage(imgUrl);
      }
    }).catch(function () {
      loadImage(imgUrl);
    });

    const loadImage = (url) => {
      const image = new Image();
      image.onload = function () {
        resolve({ path: img.path, width: this.naturalWidth, height: this.naturalHeight });
      };
      image.onerror = function () {
        resolve();
      };
      image.src = url;
    }
  });
}