// @flow

import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";
import Componentify from "react-componentify";

import {
  brTagConverter,
  boldConverter,
  linkConverter,
  linkConverterV2,
  linkConverterSelf,
  spanClassTagConverter
} from "../lib/componentifyConverters";
import { TimerTemplate } from "../../web-shared/components/TimerTemplate";
import TimerContainer from "../containers/TimerContainer";
import CheckIcon from "../../root/img/modal/check.svg";
import RightArrow from "../../root/img/modal/right-arrow.svg";
import {__} from "../lib/translate";

type Props = {
  title: string,
  titlecolor: string,
  description: string,
  descriptioncolor: string,
  btntext: string,
  btntextcolor: string,
  btnlink: string,
  btnbackground: string,
  disclaimer: string,
  disclaimercolor: string,
  img: string,
  img2x: string,
  img2xSize: string,
  imgSize: string,
  backgroundcolor: string,
  leftpartbackgroundcolor: string,
  label: string,
  lefttitletext: string,
  lefttitletextcolor: string,
  headertext: string,
  headertextcolor: string,
  channelid: number | string,
  timerStartTime?: string | number | boolean,
  timerEndTime?: string | number | boolean,
  timertextcolor?: string | boolean,
  timerbackgroundcolor?: string | boolean,
  useLocalTime?: boolean,
  middletext: string,
  middletextcolor: string,
  bulletcolor: string,
  bullet1: string,
  bullet2: string,
  bullet3: string,
  bullettextcolor: string,
  sendAnalyticsEvent: () => void,
  closeModal: () => void
};

const PromoHorizontal2 = ({
  title,
  titlecolor,
  description,
  descriptioncolor,
  btntext,
  btntextcolor,
  btnlink,
  btnbackground,
  disclaimer,
  disclaimercolor,
  img,
  img2x,
  img2xSize,
  imgSize,
  backgroundcolor,
  leftpartbackgroundcolor,
  label,
  lefttitletext,
  lefttitletextcolor,
  headertext,
  headertextcolor,
  channelid,
  timerStartTime = null,
  timerEndTime = null,
  timertextcolor,
  timerbackgroundcolor,
  useLocalTime = false,
  middletext,
  middletextcolor,
  bulletcolor,
  bullet1,
  bullet2,
  bullet3,
  bullettextcolor,
  sendAnalyticsEvent,
  closeModal
} : Props) => {

  const onButtonClick = () => {
    sendAnalyticsEvent({
      category: "Promo modal button",
      action: "click",
      label: label,
      os: 4
    }, {
      channelid: channelid
    });

    // if (typeof gaAll == 'function') {
    //   gaAll("event", "button-promo-offer-modal-" + channelid, "click");
    // }

    if (typeof ga4 == 'function') {
      ga4({
        eventname: "select_promotion",
        ecommerce: {
          items: [{
            promotion_name: label,
            creative_slot: "modal",
            item_id: channelid
          }]
        }
      })
    }
    closeModal();
  };

  const getTimerData = () => {
    if (!timerStartTime || !timerEndTime) {
      return false;
    }

    const formattedStart = timerStartTime.replace(" ", "T");
    const formattedEnd = timerEndTime.replace(" ", "T");

    const timer = {
      start: new Date(formattedStart).getTime(),
      end: new Date(formattedEnd).getTime()
    };

    if (!useLocalTime) {
      const offset = new Date().getTimezoneOffset()*60*1000;
      timer.start += offset * -1;
      timer.end += offset * -1;
    }

    return timer;
  };

  const constructURL = () => {
    const link = new URL(btnlink);
    
    link.searchParams.delete("channelid");
    link.searchParams.delete("label");
    link.searchParams.append("channelid", channelid);
    link.searchParams.append("label", label);

    return link.href;
  }

  const renderTimer = () => {
    const timerData = getTimerData();
    const timeNow = new Date().getTime();
    
    if (!timerData || timerData.start > timeNow || timerData.end < timeNow) {
      return null;
    }

    return (
      <TimerWrapper>
        <HurryUpText color={"999999"}>{__("timer_hurryup", "Hurry up! The promotion will end in...")}</HurryUpText>
        <TimerContainer
          timeExpire={timerData.end}
          timeExpireCallback={() => {}}
          // showLabels={false}
          renderTimer={TimerTemplate}
          textColor={timertextcolor || "999999" || "fff"}
          timerColor={timerbackgroundcolor}
          size="big"
          // translateLabels={false}
        ></TimerContainer>
      </TimerWrapper>
    );
  };

  const imgsrc = window.devicePixelRatio > 1 ? img2x : img;
  const imgWidth = imgSize.split("x")[0];
  const imgHeight = imgSize.split("x")[1];
  const promoLink = constructURL();
  const converters = [brTagConverter, boldConverter, linkConverterV2, linkConverterSelf, spanClassTagConverter];

  return (
    <Wrapper className="promo-modal-wrapper">
      <BoxContainer className="promo-modal-box-container">
        <Box>
          <a href={promoLink} onClick={onButtonClick} target="_blank">
            <Banner src={imgsrc}/>
          </a>
        </Box>

        <Box bgcolor={leftpartbackgroundcolor} className="text-box">
          <BoxInnerWrapper>
            <TopText color={headertextcolor}>
              <Componentify
                text={headertext}
                converters={converters}
              />
            </TopText>
            <MiddlePart>
              <DiscountText color={titlecolor}>
                <Componentify
                  text={title}
                  converters={converters}
                />
              </DiscountText>
              <MiddleText color={middletextcolor}>
                <span>
                  <Componentify
                    text={middletext}
                    converters={converters}
                  />
                </span>
              </MiddleText>
              <List>
                <ListItem color={bullettextcolor}>
                  <CheckIcon height="8px" width="11px" fill={"#" + bulletcolor}/>
                  <span>
                    <Componentify
                      text={bullet1}
                      converters={converters}
                    />
                  </span>
                </ListItem>
                <ListItem color={bullettextcolor}> 
                  <CheckIcon height="8px" width="11px" fill={"#" + bulletcolor}/>
                  <span>
                    <Componentify
                      text={bullet2}
                      converters={converters}
                    />
                  </span>
                </ListItem>
                <ListItem color={bullettextcolor}>
                  <CheckIcon height="8px" width="11px" fill={"#" + bulletcolor}/>
                  <span>
                    <Componentify
                      text={bullet3}
                      converters={converters}
                    />
                  </span>
                </ListItem>
              </List>
              {renderTimer()}
            </MiddlePart>
            <Button
              href={promoLink}
              color={btntextcolor}
              background={btnbackground}
              onClick={onButtonClick}
              target="_blank"
            >
              {btntext}
              <IconWrapper>
                <RightArrow width="15.6px" height="15.6px" fill={"#" + btntextcolor}/>
              </IconWrapper>
            </Button>
            {disclaimer ? (
              <Disclaimer color={disclaimercolor}>{disclaimer}</Disclaimer>
            ) : null}
          </BoxInnerWrapper>
        </Box>
      </BoxContainer>
    </Wrapper>
  );
}

export default PromoHorizontal2;

const Wrapper = styled.div`
  text-align: center;
  width: 800px;
  z-index: 0;
  position: relative;
  border-radius: 17px;
  overflow: hidden;
  min-height: 500px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Title = styled.div`
  display: inline-block;
  width: 100%;
  /* margin: 16px 0px; */
  margin: 16px 0px 6px 0px;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.22;
  letter-spacing: -0.3px;
  text-align: center;
  color: ${props => (props.color ? "#" + props.color : "#222")};
`;

const Banner = styled.div`
  background-image: ${props => "url(" + props.src + ")"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 400px;
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;

  @media (max-width: 430px) {
    width: 100% !important;
    height: auto !important;
  }
`;

const Description = styled.div`
  display: inline-block;
  /* margin: 16px auto; */
  margin: 0px auto;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
  color: ${props => (props.color ? "#" + props.color : "#222")};
  padding: 0px 30px;
  box-sizing: border-box;
`;

const Button = styled.a`
  display: inline-block;
  width: 100%;
  text-decoration: none;
  box-sizing: border-box;
  padding: 8px 17px;
  margin: 0 auto;
  border-radius: 7px;
  box-shadow: 0px 2px 0 0 rgba(0, 0, 0, 0.05);
  background-color: ${props =>
    props.background ? "#" + props.background : "#17bed0"};
  font-size: 17px;
  font-weight: bold;
  line-height: 27px;
  letter-spacing: -0.1px;
  text-align: center;
  color: ${props => (props.color ? "#" + props.color : "#fff")};
  cursor: pointer;
  position: relative;
`;

const DiscountText = styled.div`
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -1.33px;
  text-align: center;
  color: ${props => props.color ? "#" + props.color : "#000000"};
  /* text-transform: capitalize; */
  margin-bottom: 20px;
`;

const Disclaimer = styled.div`
  display: inline-block;
  margin: 10px auto;
  width: 100%;
  font-size: 11px;
  font-weight: normal;
  line-height: 1.18;
  text-align: center;
  color: ${props => (props.color ? "#" + props.color : "#868686")};
`;

const BoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 500px;
  height: 100%;
`;

const Box = styled.div`
  position: relative;
  display: inline-block;
  width: 50%;
  min-height: 373px;
  box-sizing: border-box;
  overflow: hidden;

  &.text-box {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-width: 400px;
    min-height: 500px;
    height: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: ${({bgcolor}) => bgcolor ? "#" + bgcolor : "transparent"};
    z-index: -1;
  }
`;

const BoxInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  min-height: 500px;
  box-sizing: border-box;
`;

const HurryUpText = styled.div`
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: -0.33px;
  text-align: center;
  color: ${({color}) => color ? "#" + color : "#0e0e1e"};
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const TimerWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 7px;
  border: solid 1px #f6f6f6;
  background-color: #f8f8f8;
`;

const TopText = styled.div`
  display: inline-block;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.54px;
  text-align: center;
  color: ${({color}) => color ? "#" + color : "#000"};
  text-transform: uppercase;
`;

const MiddleText = styled.div`
  display: inline-block;
  width: 100%;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({color}) => color ? "#" + color : "#000"};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  & > span {
    margin: 0px 20px;
  }

  &::before,
  &::after {
    content: "";
    display: inline-block;
    height: 1px;
    width: 100%;
    background-color: #eeeeee;
    flex: 1;
  }
`;

const List = styled.div`
  list-style: none;
  margin-bottom: 0px;
`;

const ListItem = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: ${props => (props.color ? "#" + props.color : "#000")};
  margin: 3px 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > span {
    margin-left: 10px;
  }

  svg {
    flex-shrink: 0;
  }
  
  &:first-of-type {
    margin-top: 0px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const MiddlePart = styled.div`
  margin: 15px auto;
  width: 100%;
  display: inline-block;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 11px;
  right: 12px;
`;